import React from 'react';
import SVGComponent from './FooterSvg';

export const Footer = () => {
  return (
    <div style={{ height: "200px" }} className="-z-10 fixed bottom-0 flex justify-end items-end pt-5   px-0 w-full">
      <SVGComponent />
    </div>
  )
}
