import React from 'react'
import { Menu } from '../components/Menu';
import { Footer } from '../components/Footer';

export default function Main({ children }) {
  return (
    <div className='relative flex flex-col justify-between h-full' >
      <div className='w-full h-full px-2 pt-10 md:px-10 lg:px-28'>
        <Menu />
        {children}
      </div>
      <Footer />
    </div>
  )
}
