import './App.css';
import {Route, BrowserRouter, Routes} from 'react-router-dom'
import Main from './layouts/Main';
import { Home } from './layouts/Home';
import { About } from './layouts/About';
import { Game } from './layouts/Game';
import { Success } from './layouts/Success';


function App() {
  return (
    <BrowserRouter>
    <Main>
    <Routes>
      <Route path='/play' element={<Game />} />
      <Route path='/about' element={<About />} />
      <Route path='/home' element={<Home />} />
      <Route path='/success' element={<Success />} />
      <Route path='/' element={<Home />} />
      <Route path='/*' element={<Home />} />
    </Routes>
    </Main>
    </BrowserRouter>
  );
}

export default App;
