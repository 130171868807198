import React, { useEffect, useState } from 'react';
import success from '../assets/images/success_img.svg';
import { useNavigate } from 'react-router-dom';

import links from '../constants/links';
import { Loader } from '../components/Loader';

export const Success = () => {

    const navigate = useNavigate();

    const goTo = (page) => {
        navigate(`/${page}`)
    }
    // for prod env, use "PROD" and "DEV" for dev env
    const env = "PROD";

    const [result, setResult] = useState({
        topAllTime: 0,
        top30days: 0,
        promptMostDifficult: ""
    })

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function loadResult() {
            const response = await fetch(`${links[env].loadResult}${localStorage.getItem('arena-uid')}/${sessionStorage.getItem('session')}`);
            if (response.ok) {
                const data = await response.json();
                setResult(data)
                sessionStorage.removeItem('session');
                sessionStorage.removeItem('arena_level');
                sessionStorage.removeItem('arena-data');
                setIsLoading(false)
            } else {
                console.log("error: ", response)
            }
        }

        loadResult()
    }, [])


    return (
        <div>
            {isLoading ? <Loader /> : <>
                <div className="flex flex-col items-center self-stretch justify-center gap-10 px-5 py-5">
                    <div className="flex items-center justify-center -mb-10 rounded-2xl w-36 h-36 md:mb-6 lg:w-80 lg:h-60 md:w-60 md:h-40">
                        <img src={success} alt="success" className='h-96 w-96' />
                    </div>


                    <div className="font-game text-primary text-center text-4xl leading-[normal] capitalize">Well done !</div>

                    <div className="self-stretch text-primary text-xl font-dmMonoMedium leading-[2.125rem] text-center">Your vote has been tallied! Thanks for contributing to the LLM Arena open-source dataset.
                        Here are some quick stats:
                    </div>
                    <ul className='list-disc'>
                        <li className='py-2 font-dmMonoLight'>
                            You are in the top {result.topAllTime}% contributors of all times

                        </li>
                        <li className='py-2 font-dmMonoLight'>
                            You are in the top {result.top30days}% contributors of the past month
                        </li>

                        <li className='py-2 font-dmMonoLight'>
                            The prompt that was the most difficult to arbitrate was « <b>{result.promptMostDifficult}</b> »

                        </li>
                    </ul>

                </div>

                <div className="flex items-center self-stretch justify-center gap-5 px-5 py-4">
                    <button onClick={() => goTo('play')} className="flex items-center self-stretch justify-center w-32 h-12 gap-2 p-2 rounded-lg hover:shadow-3xl hover:shadow-secondary-900 md:w-32 md:h-14 lg:w-40 bg-tertiary-900">
                        <div className="Mono'] text-white font-['DM font-medium leading-6">Start a new session</div>
                    </button>
                </div>
            </>}
        </div>

    )
}
