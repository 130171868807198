import React from 'react'

export const PlayerChoice = ({ t, generationOne, generationTwo }) => {
    return (
        <div className="flex px-1 py-0 mx-auto mt-8 mb-24 md:px-5 lg:px-10">
            <div className="flex-1 gap-4 w-[49%] px-2">
                <div className="mx-auto mb-4 flex justify-center items-center gap-2.5 p-0 w-[8rem] md:w-[11.875rem] bg-tertiary-200 text-primary text-center font-dmMonoMedium text-[24px] md:text-[2rem] font-medium leading-[48px]">
                    Model 1
                </div>
                <div className="w-full h-full gap-2.5 p-2 md:p-2 lg:p-4 rounded-2xl border-4 border-primary bg-secondary font-dmMonoLight text-black font-['DM text-sm md:text-xl lg:text-2xl font-medium sm:leading-[5px] md:leading-[48px] shadow-3xl shadow-secondary-900">
                    {generationOne.generation}
                </div>
            </div>
            <div className="flex-1   gap-4 w-[49%] px-2">
                <div className="mx-auto mb-4 flex justify-center items-center gap-2.5  p-0 w-[8rem] md:w-[11.875rem] bg-[#f2d895] font-dmMonoMedium text-primary text-center font-['DM text-[24px] md:text-[2rem] font-medium leading-[48px]">
                    Model 2
                </div>
                <div className="w-full h-full gap-2.5 p-2 md:p-2 lg:p-4 rounded-2xl border-4 border-primary bg-secondary font-dmMonoLight text-black font-['DM text-sm md:text-xl lg:text-2xl font-medium sm:leading-[5px] md:leading-[48px] shadow-3xl shadow-secondary-900">
                    {generationTwo.generation}
                </div>
            </div>
        </div>
    )
}
