import React from 'react'

export const PickWinner = ({ t, handleSubmitChoice,isDisable }) => {
    return (
        <div className='text-center mt-6 pb-5'>
            <p className="flex justify-center mb-6 items-center gap-2.5 self-stretch text-[#4c2215] font-dmMonoLight text-2xl font-medium leading-[2.375rem]">
                {t('pickWinner')}
            </p>

            <div className="flex justify-around items-center gap-10 self-stretch py-0 mb-8">
                <button style={{cursor: isDisable ? "not-allowed" : "pointer"}} disabled={isDisable} onClick={() => handleSubmitChoice("left")} className="z-10 hover:shadow-3xl hover:shadow-secondary-900 flex flex-col justify-between items-center self-stretch  gap-2 p-4 w-24 h-12 md:w-32 md:h-14 lg:w-40  rounded-lg bg-tertiary-900">
                    <svg width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5396 9.34993C10.9302 8.9594 10.9302 8.32624 10.5396 7.93572C10.1491 7.54519 9.51594 7.54519 9.12541 7.93572L5.12541 11.9357C4.73489 12.3262 4.73489 12.9594 5.12541 13.3499L9.12541 17.3499C9.51594 17.7405 10.1491 17.7405 10.5396 17.3499C10.9302 16.9594 10.9302 16.3262 10.5396 15.9357L8.24673 13.6428H19.8326C20.3849 13.6428 20.8326 13.1951 20.8326 12.6428C20.8326 12.0905 20.3849 11.6428 19.8326 11.6428H8.24673L10.5396 9.34993Z" fill="white" />
                    </svg>
                </button>
                <button style={{cursor: isDisable ? "not-allowed" : "pointer"}} disabled={isDisable} onClick={() => handleSubmitChoice("middle")} className="z-10 hover:shadow-3xl hover:shadow-secondary-900 flex justify-center text-white items-center self-stretch gap-2 p-4  rounded-lg w-20 h-12 md:w-24 md:h-14 text-2xl font-medium leading-6  bg-tertiary-900">
                        =
                </button>
                <button style={{cursor: isDisable ? "not-allowed" : "pointer"}} disabled={isDisable} onClick={() => handleSubmitChoice("right")} className="z-10 hover:shadow-3xl hover:shadow-secondary-900 flex flex-col justify-between items-center gap-2 p-4 w-24 h-12 md:w-32 md:h-14 lg:w-40 rounded-lg bg-tertiary-900 self-stretch">
                    <svg width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.4605 15.9357C14.0699 16.3262 14.0699 16.9594 14.4605 17.3499C14.851 17.7405 15.4842 17.7405 15.8747 17.3499L19.8747 13.3499C20.2652 12.9594 20.2652 12.3262 19.8747 11.9357L15.8747 7.93572C15.4842 7.54519 14.851 7.54519 14.4605 7.93572C14.0699 8.32624 14.0699 8.95941 14.4605 9.34993L16.7534 11.6428H5.16748C4.6152 11.6428 4.16748 12.0905 4.16748 12.6428C4.16748 13.1951 4.6152 13.6428 5.16748 13.6428H16.7534L14.4605 15.9357Z" fill="white" />
                    </svg>
                </button>
            </div>
        </div>
    )
}
