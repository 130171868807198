import React from 'react'

export const About = () => {
  return (
    <div className="">
      <div className="flex flex-col items-start self-stretch justify-center gap-10 py-4">
        <div className="flex flex-col items-center self-stretch gap-2">
          <div className="flex justify-center items-center gap-2.5">
            <div className=" text-[#4c2215] font-game text-[2rem] leading-[48px]">What is LLM Arena</div>
            <div className=" text-[#4c2215] font-endless text-[2rem] leading-[48px]">?</div>
          </div>
          <div className="self-stretch text-[#4c2215] font-dmMonoLight text-lg leading-8">LLM Arena has been developed by Mistral AI to address the lack of robust open-source RLHF dataset. All collected data will be contributed back to the community.</div>
        </div>
      </div>
      <div className="grid items-start self-stretch justify-center grid-cols-2 gap-10 py-4">

        <div className="flex flex-col items-start justify-center col-span-1 gap-2 frame_2419">
          <div className=" text-[#4c2215] font-dmMonoMedium text-[2rem] font-medium leading-[48px]">The prompts </div>
          <div className=" self-stretch text-[#4c2215] font-dmMonoLight text-lg leading-8">Prompts have been generated by various models, using both synthetic and real questions from open-source datasets. The answers have also been generated by different models using different parameters to increase variance between answers.</div>
        </div>
        <div className="flex flex-col items-start justify-center col-span-1 gap-2 frame_2419">
          <div className=" text-[#4c2215] font-dmMonoMedium text-[2rem] font-medium leading-[48px]">Collected data</div>
          <div className=" self-stretch text-[#4c2215] font-dmMonoLight text-lg leading-8">
            The data collected follows: <br />
            - Country of the respondent <br />
            - Unique (anonymous) identifier of the respondent <br />
            - Unique (anonymous) identifier of the session (set of 8 questions) <br />
            - The winning generation (or if it is a draw)<br />
            - The time spent to pick a winner<br /><br />

            No personally identifiable information is stored. In particular, respondent IPs are not stored.
          </div>
        </div>
      </div>
    </div>
  )
}
