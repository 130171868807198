import React, { useState, useEffect } from 'react'

export default function CustomCheckBox({ handleState, id, currentVals }) {
  const [checked, setChecked] = useState(currentVals.indexOf(id) !== -1)

  const handleChange = (e) => {
    handleState(id)
    setChecked(e.target.checked)
    // do whatever you want with isChecked value
  }

  useEffect(() => {
    setChecked(currentVals.indexOf(id) !== -1)
    return () => {
    };
  }, [currentVals, id])
  return (
    <div className="relative flex gap-2">
      <input id={id} onChange={e => handleChange(e)} type="checkbox" name="checkbox" value="1" className="mr-2
                 appearance-none w-5 h-5 border-2 border-[#E7D1A0] rounded-md bg-[#E7D1A0]
                 shrink-0
                 checked:border-0
                 "
      />

      {checked && (
        <svg
          className="
                        absolute 
                        w-4 h-4
                        mt-[2px] ml-[2px]
                        peer-checked:block
                        pointer-events-none
                        "
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <polyline points="20 6 9 17 4 12"></polyline>
        </svg>
      )}
    </div>
  )
}
