import React from 'react'

export const QuestionCard = ({ question }) => {
    return (
        <div id="question" className=" text-[#4c2215] font-game text-[24px] md:text-[2rem] leading-[40px] font-semibold md:font-bold text-center mt-5">

            <p>
                {question}
            </p>
        </div>
    )
}
