const links = {
    "PROD": {
        loadData: "/api/match/",
        loadResult: "/api/result/",
        loadCategories: "/api/categories",
        loadLanguages: "/api/lang",
        postMatch: "/api/rank",
    },
    "DEV": {
        loadData: "http://localhost:8000/data",
        loadCategories: "http://localhost:8000/categories",
        loadLanguages: "http://localhost:8000/lang",
        postMatch: "http://localhost:8000/match",
        loadResult: "http://localhost:8000/result",
    },
}

export default links