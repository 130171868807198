import React from 'react'

export const Stepper = ({current, total}) => {

    const doneStep = "w-full  h-2 rounded-full bg-[#62c476]";
    const notDoneStep = "w-full  h-2 rounded-full bg-secondary-500";
    
    return (
        <div id='scrollToHere' className="flex flex-col items-start self-stretch gap-1 pt-4 mt-16">
            <div className="self-stretch text-[#4c2215] font-dmMonoMedium text-xs mx-auto">{current} of {total} steps completed</div>
            <div className="flex self-stretch w-full gap-1">
                {[...Array(total).keys()].map(step => <div className={(step < current) ? doneStep : notDoneStep} />)}
            </div>
        </div>
    )
}
